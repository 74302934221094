import { validatorPhoneEQI } from '@/services/http/apiValidatorEQI';
import { sendEventToWebhookPP } from '@/services/http/webhookPP';
import { inputGroups } from '@/config/form/inputGroups';
import { statesDDD } from '@/config/form/states';
import { getDecryptedCookie } from '@/common/cookies';
import { validateEmail, validatePhone } from '@/common/validators';
import { ProgressiveProfile } from '../ProgressiveProfile';
import { renderInput } from './Inputs';
import { mountSpanError } from './utils/mountSpanError';
export class Form {
    formId;
    styles;
    constructor(formId = 'form-progressive-profile', styles) {
        this.formId = formId;
        this.styles = styles;
    }
    getFormElement() {
        return window.document.querySelectorAll(`[id^=${this.formId}]`);
    }
    /** Method that render the form, according to the formId
     * @param {string} formId
     */
    renderForm(formId) {
        const groupToShow = inputGroups[formId || 'default'];
        const forms = this.getFormElement();
        forms.forEach((form) => {
            const FormProgressiveProfile = document.createElement('div');
            this.queryParamsFields(FormProgressiveProfile);
            form.insertAdjacentElement('afterbegin', FormProgressiveProfile);
            groupToShow.inputs.forEach((input) => {
                const divInput = renderInput(input, this.styles);
                FormProgressiveProfile.appendChild(divInput);
                if (input.name === 'Email' || input.name === 'MobilePhone')
                    this.validateFields(input, divInput);
            });
        });
    }
    queryParamsFields(form) {
        const currentUrlParams = window.location.search;
        if (currentUrlParams) {
            const queryParams = currentUrlParams
                .split('?')[1]
                .split('&')
                .map((queryParam) => queryParam.split('='));
            queryParams.map(([key, value]) => {
                const divHiddenInput = renderInput({
                    id: key,
                    label: '',
                    name: key,
                    type: 'hidden',
                    value,
                });
                form.appendChild(divHiddenInput);
            });
        }
    }
    /** Email and Phone validations accordding to the events */
    validateFields(input, divInput) {
        const inputName = document.querySelectorAll(`#form-field-${input.name}`);
        inputName.forEach((input) => {
            input.addEventListener('blur', (input) => {
                const elementTarget = input.target;
                if (elementTarget.type === 'email')
                    this.handleEmailValidation(elementTarget, divInput);
                if (elementTarget.name === 'form_fields[MobilePhone]')
                    this.handlePhoneValidations(elementTarget, divInput);
            });
            if (input.name === 'form_fields[MobilePhone]')
                input.addEventListener('keyup', this.formatPhone);
        });
    }
    /** Check if email is valid */
    handleEmailValidation(input, divInput) {
        const checked = validateEmail(input.value);
        if (!checked) {
            mountSpanError(divInput);
            this.submitStatus(true);
        }
        else {
            this.removeErrorInput(divInput);
            this.submitStatus(false);
        }
    }
    /** Insert the mask (XX) XXXX-XXXX into the Phone */
    formatPhone(event) {
        const input = event.target;
        const removeNonNumericRegex = /\D/gi;
        const maskPhone = /(\d{2})(\d{5})(\d{4})/gi;
        const formattedPhone = input.value
            .replace(removeNonNumericRegex, '')
            .substring(0, 11)
            .replace(maskPhone, '($1) $2-$3');
        input.value = formattedPhone;
    }
    /** Check all the phone validations */
    async handlePhoneValidations(element, divInput) {
        const unformattedPhone = element.value.replace(/\D/g, '');
        const isValid = validatePhone(unformattedPhone);
        const validaEQI = await validatorPhoneEQI(unformattedPhone);
        if (!isValid || !validaEQI) {
            mountSpanError(divInput, this.styles);
            this.submitStatus(true);
        }
        else {
            this.removeErrorInput(divInput);
            this.submitStatus(false);
        }
        this.setStateByDDD(unformattedPhone);
    }
    /** Remove the error message from the screen */
    removeErrorInput(divInput) {
        const errorSpan = divInput.querySelectorAll('.message-danger');
        errorSpan.forEach((element) => {
            element.remove();
        });
    }
    /** Disable or Able click on submit button */
    submitStatus(status) {
        const forms = this.getFormElement();
        forms.forEach((form) => {
            const btSubmit = form.querySelector('[type="submit"]');
            if (btSubmit instanceof HTMLButtonElement ||
                btSubmit instanceof HTMLInputElement) {
                btSubmit.disabled = status;
            }
        });
    }
    /** Set the user state based on his phone DDD */
    setStateByDDD(phone) {
        const ddd = phone.substring(0, 2);
        const addressInputs = document.querySelectorAll('#form-field-State');
        const estado = statesDDD[ddd];
        addressInputs.forEach((input) => {
            input.value = estado;
        });
    }
    /** If the user has previously filled out a form, a cookie is saved with data (name, email and phone) and now is automatically fill
  
     * @param leadData
     */
    renderAutofillInputs(leadData) {
        const nameFields = document.querySelectorAll('[id^=form-field-Name]');
        const emailFields = document.querySelectorAll('[id^=form-field-Email]');
        const mobilePhoneFields = document.querySelectorAll('[id^=form-field-MobilePhone]');
        nameFields.forEach((field) => {
            field.value = leadData.name;
        });
        emailFields.forEach((field) => {
            field.value = leadData.email;
        });
        mobilePhoneFields.forEach((field) => {
            field.value = leadData.phone;
        });
    }
    /** Takes the data filled in the form and organizes into an object */
    listenerSubmitForm() {
        const inputsToSubmit = {};
        const forms = this.getFormElement();
        forms.forEach((form) => {
            form.addEventListener('submit', async (e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const formObject = Object.fromEntries(formData.entries());
                Object.entries(formObject).forEach((inputValue) => {
                    const inputName = inputValue[0]
                        .replace('form_fields[', '')
                        .replace(']', '');
                    if (typeof inputValue[1] === 'string') {
                        inputsToSubmit[inputName] = inputValue[1];
                    }
                });
                this.submitLead(inputsToSubmit);
            });
        });
    }
    /** Send form data to webhook and change the cookie ID, if everything it's OK */
    async submitLead(inputsToSubmit) {
        await sendEventToWebhookPP(inputsToSubmit);
        const newPP = new ProgressiveProfile();
        const sendValueCookie = getDecryptedCookie(newPP.ppCookieName);
        newPP.changeFormId(JSON.parse(sendValueCookie));
    }
}
